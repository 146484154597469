'use strict';

import amplitude from 'amplitude-js';

const AMPLITUDE_API_ENDPOINT_PATH = '/integration/amplitude/event';
const startsWithHttpOrHttps = new RegExp(/https?:\/\//g);

export function initAmplitudeClient(window, sessionId) {
  const env = window.SCW_ENV;

  if (env.AMPLITUDE_APIKEY) {
    const apiEndpoint = getAmplitudeApiEndpoint(env.ApiEndpoint);
    const amplitudeInstance = window.amplitude?.getInstance();
    const amplitudeSessionId = getAmplitudeSessionId(window);

    if (!amplitudeInstance) {
      window.amplitude = amplitude;
    }

    window.amplitude.getInstance().init(env.AMPLITUDE_APIKEY, null, {
      includeUtm: true,
      includeGclid: true,
      includeReferrer: true,
      saveParamsReferrerOncePerSession: false,
      disableCookies: true,
      headers: {
        'X-Session-ID': `${sessionId}`,
      },
      forceHttps: !!env.ENV_NAME && env.ENV_NAME !== 'local', //force HTTPS if platform is deployed
      ...(apiEndpoint && { apiEndpoint }),
    });
    // Set the sessionId to the existing session, if there was one
    if (amplitudeSessionId) {
      window.amplitude.getInstance().setSessionId(amplitudeSessionId);
    }

    // Set the version, if there is one
    if (env.GIT_COMMIT) {
      window.amplitude.getInstance().setVersionName(env.GIT_COMMIT);
    }
  }
}

export function getAmplitudeApiEndpoint(apiEndpoint) {
  if (!apiEndpoint?.match(startsWithHttpOrHttps)) {
    return;
  }

  return `${apiEndpoint.split('//')[1]}${AMPLITUDE_API_ENDPOINT_PATH}`;
}

export function getAmplitudeSessionId(window) {
  return window.amplitude?.getSessionId();
}
